.pane-wcmc-in-your-neighborhood-events {
  margin-top: 20px;

  h3 {
    font-size: 30px;
  }

  .view-header {
    margin-bottom: 10px;
  }
}
// FAD Search
.pane-wcmc-react-fap-search {

}

// Find a Physician Search Form
// Note: due to this being a shared React component that is called
// within multiple panes, this class wrapper is being used instead of the pane class.
.fap-search-form-wrapper {
  position: relative;
  display: block;

  .form-mini-container {
    border-radius: 12px;
    color: #fff;
    border: 10px solid rgba(255,255,255,.9);
    box-shadow: 0px 3px 9px rgba(0,0,0,0.5);
    position: relative;
    z-index: 100;
    margin: 10px;

    @include breakpoint($md) {
      width: 960px;
      margin: 140px auto 60px;
    }

    @include breakpoint($lg) {
      width: 900px;
      margin: 160px auto 80px;
    }

    .fad-wcm-logo {
      text-align: center;

      img {
        display: none;

        @include breakpoint($sm) {
          display: inline-block;
          width: 300px;
          margin: 10px 0 20px;
          margin-left: 45px;
        }
      }
    }

    .fad-marketing-text {
      margin-bottom: 20px;
      font-size: 25px;
      font-family: $wcm-bold;
      color: $wcm-dark-gray;
      text-align: center;

      @include breakpoint($sm) {
        font-size: 30px;
        margin-left: 45px;
      }
    }

    .fad-marketing-text-bottom {
      padding: 15px 0 10px;
      text-align: center;

      span {
        font-size: 0.9rem;
        color: $wcm-med-gray;
        text-transform: uppercase;
        margin-top: 0.5rem;
        font-family: $wcm-bold;
        margin-right: 7px;
        letter-spacing: 0.05rem;

        @include breakpoint($md) {
          font-size: 1.2rem;
        }
      }

      img {
        width: 140px;
        position: relative;
        top: -3px;

        @include breakpoint($md) {
          width: 215px;
          top: 1px;
        }
      }
    }
  } // end .form-mini-container

  .bg-image-wrapper img {
    width: 100%;
  }

  .fap-search-form {
    // no background on mobile
    @include breakpoint($sm) {
      background: url(../../../../themes/weillcornell/images/find-a-doctor-3.jpg) 50% -10px;
      background-size: cover;
      background-repeat: no-repeat;
    }

    // Note: these may need adjustments if a new image is required.

    @media screen and (min-width: 1450px) {
      background-position: 50% -50px;
    }

    @media screen and (min-width: 2000px) {
      background-position: 50% -80px;
    }

    h1 {
      font-size: 40px;
      color: #fff;
    }

    h3, legend {
      text-align: center;
      color: #fff;
      font-size: 28px;
      margin-bottom: 15px;
      text-shadow: 0px 3px 9px #444;
      padding-top: 40px;

      @include breakpoint($sm) {
        font-size: 36px;
        margin-bottom: 30px;
        margin: 60px;
        padding-top: 50px;
      }
    }

    legend {
      display: none; // for mobile
      float: none;
      font-family: $wcm-regular;
      margin: 25px 0 10px;
      line-height: 1.3;
      font-weight: 400;
      padding-top: 40px;
      padding-bottom: 20px;
      border-bottom: 0;

      @include breakpoint($sm) {
        display: block;
        padding-bottom: 10px;
      }
    }

    .cta-link {
      text-align: center;
      margin-top: 5px;

      a {
        font-size: 16px;
        color: #FFF;
        text-shadow: 0px 3px 9px #222;
      }
    }

    .fap-search-description {
      line-height: 2;
      font-size: 14px;
      padding-bottom: 10px;

      span {
        font-weight: bold;
      }
    }

    fieldset {
      width: 100% !important;
      border: 0px !important;
      padding: 0px !important;
      margin-top: 0;
      margin-left: 0;

      @include breakpoint($md) {
        margin-top: -5px;
      }
    }

    .form-group {
      margin-bottom: 0;
    }

    .react-select-prefix__control {
      border: 1px solid #e6e6e6 !important;
      border-radius: 0;

      @include breakpoint($sm) {
        border-right: none !important;
      }
    }

    .accepting-new-patients {
      margin-top: 15px;
      height: 20px; // temp
      padding-left: 0px;

      @include breakpoint($sm) {
        margin-top: 0;
      }

      input {
        margin-right: 8px;
      }

      label {
        display: inline-block;
        font-size: 15px;
        font-weight: normal;
      }
    }
  } // end .fap-search-form

  // Modifications for a new search.
  &.new-search .fap-search-form {
    legend {
      display: none;
    }
  } // end .new-search .fap-search-form

  // Modifications for when a search has been executed to reduce clutter.
  &.current-search .fap-search-form {
    background: none;

    legend {
      display: none;
    }

    .form-mini-container {
      margin-bottom: 0;
      margin-top: 0;
      box-shadow: 0px 3px 9px rgba(0,0,0,0.3);
    }
  } // end .current-search .fap-search-form

  // hack for Edge
  @supports (-ms-ime-align: auto) {
    .fap-search-form {
      background: $wcm-dark-orange;
    }
  }

  .fap-form {
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 20px 30px 15px;

    @include breakpoint($sm) {
      padding: 15px 30px 10px 50px;
    }

    .input-wrapper, .fap-search-description {
      width: 100%;

      @include breakpoint($sm) {
        width: 90%;
        float: left;
      }
    }

    label {
      font-size: 13px;
      font-weight: normal;
    }

    .button-wrapper {
      width: 100%;

      @include breakpoint($sm) {
        width: 10%;
        float: left;
      }
    }

    .search-term-field {
      @include breakpoint($sm) {
        // margin-left: 20px;
      }
    }

    .search-button {
      button {
        margin-top: 10px;
        padding: 9px 10px;
        height: 48px;
        border-radius: 4px;
        background: $wcm-dark-orange;
        border: none;
        width: 100%;

        @include breakpoint($sm) {
          margin-top: 99px;
          margin-left: -2px;
          border-radius: 0 4px 4px 0;
          width: initial;
        }

        &:hover {
          border: none;
          background: $wcm-red;
        }
      }

      img {
        width: 22px;
        margin: 0 4px;
      }
    }

    .form-control {
      max-width: initial;
    }

    .form-control {
      border: 1px solid #a29f9f;
      border-radius: 5px 0 0 5px;
      box-shadow: none;
      padding: 9px 12px;
      height: 48px;
      font-size: 13px;

      @include breakpoint($sm) {
        border-right: none;
        font-size: 15px;
      }

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
      }
    }
  } // end .fap-form
} // end .fap-search-form-wrapper

// Popular Searches
.popular-searches-outer-wrapper {
  background: #f7f7f7;

  .container {
    padding-top: 20px;
    padding-bottom: 30px;

    @include breakpoint($sm) {
      padding-top: 30px;
      padding-bottom: 40px;
    }
  }

  h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;

    @include breakpoint($sm) {
      text-align: left;
      font-size: 18px;
    }
  }

  .popular-searches-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;

    a.item {
      background: #fff;
      color: #000;
      font-size: 16px;
      font-weight: bold;
      padding: 22px 28px 22px 35px;
      border-bottom: none;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      @include breakpoint($sm) {
        flex-basis: 32%;
      }

      &:hover {
        color: $wcm-dark-orange;
      }

      span {
        flex-grow: 1;
      }

      .chevron {
        &:after {
          content: " \e802";
          text-decoration: none;
          font-family: fontello;
          color: $wcm-dark-orange;
          margin-left: 10px;
        }
      }
    }
  }
} // end Popular Searches

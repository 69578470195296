// Results page only FAP.
.pane-wcmc-react-fap-results {

  // Loading Spinner
  .loading {
    margin: 30px auto 20px;

    .loading-spinner {
      text-align: center;
    }
  }

  .results-outer-wrapper {
    margin-top: 40px;
  }

  .fap-search-results {
    clear: both;
    font-size: 14px;

    .search-result-item {
      padding: 20px;
      margin: 20px 0;
      border: 1px solid #ddd;
      box-shadow: 0px 3px 3px 0px rgba(221, 221, 221, 1);

      .provider-main-details {
        margin-bottom: 1rem;

        @include breakpoint($sm) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        @include breakpoint($sm) {
          margin-left: 25px;
        }

        hr {
          margin: 10px 0 10px;
        }
        
        @include breakpoint($xs) {
          text-align: center;
        }

        .provider-title {
          margin-top: 7px;
          margin-bottom: 12px;
    
          a {
            font-family: $wcm-bold;
            font-size: 18px;
            line-height: 24px;
            color: $wcm-dark-orange;

            @include breakpoint($sm) {
              font-size: 22px;
              line-height: 28px;
            }
          }
        }

        .primary-specialty {
          font-family: $font-family-sans-serif;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          color: #555;

          @include breakpoint($sm) {
            font-size: 18px;
            line-height: 22px;
          }
        }
    
        .online-scheduling-tags {
          margin-top: 12px;
          margin-bottom: 15px;
          font-size: 14px;
          color: #555;
        }

        .provider-badge {
          display: none;
        }
    
        .accepting-patients-wrapper, .vv-available-wrapper {
          @include breakpoint($sm) {
            // float: right;
          }
        }
    
        // Accepting new patients
        #accepting-patients, .simple-tag {
          @include breakpoint($sm) {
            margin-right: 0px;
            margin-left: 10px;
          }
        }

        .award-wrapper {
          flex: none;
          .award-image {
            width: 65px;

            @include breakpoint($sm) {
              width: 80px;
            }
          }
        }
      }

      // Provider image
      .provider-image-wrapper {
        background-color: #fff;
        padding: 8px;
        box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem #ddd;
        margin: 0 80px;

        @include breakpoint($xs) {
          text-align: center;
          margin-bottom: 10px;
        }

        @include breakpoint($sm) {
          margin: 0;
        }

        .provider-image-inner-wrapper {
          overflow: hidden;
          padding-bottom: 100%;
          position: relative;
          width: 100%;

          img {
            position: absolute;
            width: 100%;
            left: 50%;
            margin-left: -50%;
          }
        }
  
        a {
          border-bottom: none;
        }

        .provider-image-img {
          max-width: 100%;
    
          &:hover {
            cursor: pointer;
          }
        }
      } // end provider image
  
      .accepting-patients-wrapper, .vv-available-wrapper {
        @include breakpoint($sm) {
          margin-bottom: 10px;
          float: left;
        }
      }

      .online-scheduling-info-container {
        color: #555;
        font-size: 13px;
        margin: 5px 0 0;
        display: inline-block;

        @include breakpoint($sm) {
          margin: 5px 0 20px;
        }
    
        .text-tag {
          margin-bottom: 6px;
    
          &:last-of-type {
            margin-right: 0;
          }
    
          span:first-of-type {
            margin-right: 5px;
          }
    
          .dot {
            height: 14px;
            width: 14px;
            background-color: #66ff33;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            top: 3px;
          }
        
          &.not-accepting {
            .dot {
              background-color: #f44242;
            }
          }

          .treats-children-icon {
            opacity: 0.6;
          }

          .concierge-medicine-icon {
            opacity: 0.6;
            position: relative;
            top: -1px;
          }
        
          .icon {
            display: inline-block;
        
            img {
              width: 17px;
              height: 17px;
            }
          }
        
          .video-visit-icon {
            opacity: 0.6;
          }
        }
      }

      // Location info
      .locations-wrapper {
        @include breakpoint($sm) {
          margin-top: 30px;
        }
      }

      .location-listing-container {
        background: #f7f7f7;
        padding: 30px;
        margin-top: 30px;

        &:first-of-type {
          margin-top: 0;
        }

        .location-title {
          margin-bottom: 10px;

          a {
            font-size: 16px;
            line-height: 20px;
            font-weight: bold;
            color: $wcm-red;

            @include breakpoint($md) {
              font-size: 18px;
              line-height: 26px;
            }
          }
        }

        .location-address {
          .address-line-1 {
            font-size: 15px;
            color: #555;
            margin-bottom: 10px;
          }

          .directions-wrapper {
            display: none;
          }
        }

        .phone {
          span {
            display: block;
            font-size: 16px;
            color: $wcm-black;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .telephone-number {
            font-size: 15px;

            a {
              color: $wcm-red;
            }

            &::before {
              display: inline-block;
              content: '';
              background-image: url('/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/mobile.svg');
              background-size: contain;
              vertical-align: top;
              width: 2rem;
              height: 2rem;
              margin-right: .5rem;
            }
          }
        }

        .location-distance {
          font-size: 14px;
          color: #666;
          margin-bottom: 15px;
        }

        .call-to-action-wrapper {

          .cta-button-inner {
            text-align: center;
          }
          
          .cta-container {
            margin-top: 40px;
          }
  
          .cta-button {
            border-radius: 5px;
            padding: 15px 60px;
            font-size: 14px;
            font-weight: 700;
            height: unset;
  
            @include breakpoint($sm-only) {
              width: 100%;
            }
          }
  
          .direct-scheduling-cta {
            margin-top: 10px;
            text-align: center;

            a {
              color: $wcm-red;
            }
          }
  
          .phone-cta {
            .cta-button {
              padding: 15px 30px;
            }
          }
        }
      } // end Location info
    } // end Search Result Item

    .disable-message p {
      font-size: 1.3rem;
      line-height: 2.0rem;
    }
  } // end .fap-search-results

  // Taken from styles.scss
  .pagination-wrapper {
    text-align: center;
  }

  .top-pagination {

    @include breakpoint($xs) {
      display: none;
    }

    .pagination {
      margin: 0 0 10px;
    }
  }

  .expander-link {
    border-bottom: none;

    &:after {
      content: ' \e80f';
      text-decoration: none;
      font-family: "fontello";
      font-size: 70%;
      padding-left: 4px;
      line-height: 18px;

      &:hover {
        text-decoration: none;
      }
    }

    &.open:after {
      content: ' \e80b';
    }

    &:hover {
      cursor: pointer;
    }

    &.expanded {
      &:after {
        content: ' \e80b';
      }
    }
  }

  .more-locations-wrapper {
    width: 100%;
    float: left;
    margin-top: 15px;
  }
  // end taken from styles.scss
}

// React Select (note: these can not be nested in the pane scss due to how React renders the DOM)
.react-select-prefix__control {
  height: 48px !important;
  border: none !important;
  cursor: text !important;
  font-size: 15px !important;
}

.react-select-prefix__control--is-focused {
  outline: 5px auto -webkit-focus-ring-color !important;
}

.react-select-prefix__single-value {
  font-size: 15px !important;
  color: #555 !important;
}

.react-select-prefix__menu {
  margin-top: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  z-index: 10 !important;
}

.react-select-prefix__group-heading {
  text-align: left !important;
  font-size: 12px !important;
  padding-left: 20px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  font-weight: bold !important;

}

.react-select-prefix__option {
  padding: 11px 30px !important;
  text-align: left !important;
  color: black !important;
  font-size: 14px !important;
}

.react-select-prefix__option--is-focused {
  background: $wcm-dark-orange !important;
  color: #FFF !important;
  cursor: pointer !important;

  &:active {
    background: $wcm-dark-orange !important;
  }
}

// Google Maps

.google-map-react-wrapper {
  .info-window-details {
    .info-title a {
      font-weight: bold;
      font-size: 14px;
      color: $wcm-red;
      font-family: $font-family-sans-serif;
    }

    .address {
      margin-top: 10px;
      font-size: 12px;
      font-family: $font-family-sans-serif;
    }
  }
}
      
.react-select-prefix__option--is-selected {
  background: lighten($wcm-dark-orange, 7%) !important;
  color: #FFF !important;
}

.react-select-prefix__dropdown-indicator {
  color: $wcm-dark-orange !important;

  &:hover {
    cursor: pointer;
  }
} // end React select

// Autosuggest (note: these can not be nested in the pane scss due to how React renders the DOM)
.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  text-align: left;
  color: #333;
  // margin-left: 20px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 47px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 400px;
  overflow-y: auto;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 20px;

  a, .see-more {
    color: #333;
    border-bottom: none;
    transition: none;

    &:after {
      content: ' \e802';
      text-decoration: none;
      font-family: "fontello";
      font-size: 70%;
      padding-left: 5px;
      position: relative;
      top: -1px;
      color: #e7751d;
    }
  }

  span {
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;
    line-height: 19px;
  }
}

.react-autosuggest__suggestion--highlighted {
  background: $wcm-dark-orange;
  color: #fff;

  a, .see-more {
    color: #fff;
    transition: none;

    &:after {
      color: #fff;
    }
  }
}

.react-autosuggest__section-container {
  border-top: 1px solid #ededed;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 20px;
  font-size: 12px;
  color: #000;
}

// Location Search
.autocomplete-dropdown-container {
  text-align: left;
  color: #333;
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  font-size: 14px;
  line-height: 19px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  border-top: none !important;

  @include breakpoint($md) {
    width: 190px;
  }

  @include breakpoint($lg) {
    width: 240px;
  }

  .suggestion-item {
    cursor: pointer;
    padding: 8px 0px;
    background: #fff;

    a {
      color: #333;
      border-bottom: none;
      transition: none;
  
      &:after {
        content: ' \e802';
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        position: relative;
        top: -1px;
        color: #e7751d;
      }
    }

    span {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .suggestion-item--active {
    cursor: pointer;
    background: $wcm-dark-orange;
    color: #fff;
    padding: 8px 20px;

    a {
      color: #fff;
      transition: none;
      font-size: 14px;
      line-height: 19px;
  
      &:after {
        color: #fff;
      }
    }
  }
} // end Location Search 

// Sort by
.sortby-container {
  .react-select-container {
    .react-select-prefix__control {
      border: 1px solid #ddd !important;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;

      @include breakpoint($sm) {
        margin-bottom: 20px;
      }
      @include breakpoint($xs) {
        width: 100%;
      }
      @include breakpoint($sm-only) {
        width: 100%;
      }
    }
  }
} // end Sort by

.select-filter {
  .react-select-container {
    .react-select-prefix__control {
      border: 1px solid #ddd !important;
      border-radius: 0;
    }
  }
}

// Find a Doctor Search Review
.fap-search-review {

  @include breakpoint($xs) {
    margin-bottom: 0;
  }

  .result-overview {
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-top: 20px;
    margin-bottom: 5px;

    @include breakpoint($sm) {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .searched-term {
      font-weight: bold;
    }

    .selected-insurance {
      font-size: 14px;
      margin: 10px 0;
      display: block;
    }

    .print-icon {
      background: url('/sites/all/themes/weillcornell/images/print-icon_awesome.svg?v2');
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      width: 27px;
      height: 27px;
      margin: 0px 40px 0px 30px;
      float: right;
      filter: brightness(1.2);

      @include breakpoint($xs) {
        display: none;
      }

      &:hover{
        cursor: pointer;
        filter: brightness(0.5);
      }
    }
  }

  .fap-filters-inner {
    margin-bottom: 15px;
  }
} // end Search review

.filter-chip {
  background: #f2f2f2 !important;
  font-family: "Open Sans", sans-serif !important;
  margin: 2px 10px 2px 0;
  font-size: 13px !important;
}

// Find a Doctor Search Filters
.fap-search-filters, .mobile-filter-wrapper {
  padding: 15px;
  background: #f7f7f7;

  @include breakpoint($sm) {
    padding: 20px 20px 10px 20px;
    margin-bottom: 30px;
  }

  .filter-search-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;

    @include breakpoint($sm) {
      margin: 0 0 10px 0;
    }
  }

  .reset-filters {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: bold;

    a {
      color: $wcm-red;
    }
  }

  .reset-filters-disabled {
    a {
      color: #666;
      border-bottom: none;
      pointer-events: none;
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  .form-control {
    border-radius: 0;
  }

  .filter-filter-wrapper {
    input {
      padding: 5px;
      margin-left: 10px;
      width: 85%;

      @include breakpoint($xs) {
        border: 1px solid #e7e7e7;
      }
    }
  }

  .gender-filter, .distance-filter, .visittype-filter {
    .filter-filter-wrapper {
      display: none;
    }

    .filter-details {
      padding-top: 0;
      overflow: auto;
    }
  }

  .accepting-new-patients {
    .label-wrapper {
      font-weight: normal;
      font-size: 13px;
    }
  }

  .video-visit-filter, .accepting-new-patients-filter, .treats-children-filter, .concierge-medicine-filter {
    .filter-item {
      margin-bottom: 0;

      .checkbox-wrapper {
        padding: 6px 7px 6px 12px;
      }
    }

    .filter-item > span {
      font-weight: normal;
      font-size: 15px;
      line-height: 17px;
      font-family: "Open Sans", sans-serif;
    }
  }

  .concierge-medicine-filter {
    .filter-item {
      margin-right: 5px;
    }
  }





  .concierge-medicine-tooltip-icon {
    position: relative;

    .tooltip-contents {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      width: 250px;
      padding: 20px;
      border-radius: 5px;
      top: 40px;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.35);
      opacity: 0;
      transition: opacity 0.6s;

      @include breakpoint($sm) {
        top: -30px;
        left: 45px;
        width: 400px;
      }

      .title {
        font-weight: bold;
        font-size: 14px;
        color: $wcm-med-gray;
        margin-bottom: 10px;
      }

      p, a {
        font-size: 13px;
        line-height: 19px;
      }

      &::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        right: 50px;
        border-width: 13px;
        border-style: solid;
        border-color: transparent transparent white transparent;

        @include breakpoint($sm) {
          top: 30px;
          right: 100%;
          margin-top: -4px;
          border-color: transparent white transparent transparent;
        }
      }
    }
  
    &:hover {
      cursor: pointer;

      .tooltip-contents {
        visibility: visible;
        opacity: 1;
      }
    }
  }







  .expansion-panel-wrapper {
    .expansion-panel-summary {
        padding: 0;
        margin-right: -10px;
    }

    p {
      font-size: 16px !important;
      font-weight: bold;
      font-family: "Open Sans", sans-serif;
      padding-bottom: 0;
    }
  }

  .gender-filter, .language-filter, .specialty-filter, .expertise-filter, .distance-filter {
    margin-bottom: 15px;

    .expansion-panel-wrapper {
      padding: 0 15px;
    }

    .filter-item span:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .filter-details {
    padding: 7px 0 7px 10px;
    max-height: 400px;
    overflow-y: auto;

    fieldset {
      width: 100%;
    }

    legend {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      line-height: 17px;
      padding-bottom: 3px;
    }

    .filter-item {
      margin: 0 10px 0 0;

      span {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        line-height: 17px;
      }

      >span:last-of-type {
        margin-bottom: 5px;
      }
    }

    .checkbox-wrapper, .radiobutton-wrapper {
      padding: 7px 7px 7px 0;
    }
  }
} // end Find a Doctor Search Filters

.fap-filters {
  .select-filter {
    margin-top: 5px;

    .react-select-container {
      .react-select-prefix__menu {

        @include breakpoint($lg) {
          width: 241px;
        }
      }
    }
  }

  // Locations Filter
  .location-filter {
    margin-top: 5px;

    input {
      height: 46px;
    }
  }

  // Insurance Filter
  .insurance-filter {
    padding-bottom: 15px;
  }

  .location-filter, .insurance-filter {
    label {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
}

.sorting-options {
  .filter-search-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
  }

  @include breakpoint($xs) {
    padding: 10px 15px 20px;
    min-height: 350px; // this is required so that the dropdowns are visible when activated
    min-width: 270px;

    .col-xs-12 {
      padding: 0;
    }

    .sortby-container {
      margin-top: 10px;
    }
  }

  @include breakpoint($sm) {
    .filter-search-title {
      display: none;
    }
  }
}

.show-modal {
  margin: 7px auto;

  a {
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 15px;
    font-weight: 500;
    padding: 8px;
    width: 70%;
    display: block;
    text-align: center;
    margin: 0 auto;
  }
}

.done-button-wrapper {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px;
}

// Find a Physician Results
.panel-flexi-a-fullwidth #main-content {
  padding: 0;
}

.date-scrubber-and-sort {
  padding: 0px !important;

  .col-md-2 {
    @include breakpoint($sm) {
      padding: 0px !important;
    }
  }
}

.view-number-results {
  .react-select-container {
    .react-select-prefix__control {
      border: 1px solid #ddd !important;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;

      @include breakpoint($sm) {
        margin-bottom: 20px;
      }
      @include breakpoint($xs) {
        width: 100%;
      }
      @include breakpoint($sm-only) {
        width: 100%;
      }
    }
  }
}

.filter-and-results-wrapper {
  border-top: 1px solid #e7e7e7;

  @include breakpoint($sm) {
    padding-top: 20px;
  }

  @include breakpoint($xs) {
    border-top: none;
    padding-top: none;

    .loading {
      margin-top: 20px;
      margin-bottom: 30px;
    }

    // change the order of components on mobile
    .row {
      display: flex;
      flex-direction: column;

      .col-xs-12:first-of-type {
        order: 2;
      }

      .standard-widget {
        order: 1;
      }

      .col-xs-12:last-of-type {
        order: 3;
      }
    }
  }

  .loading {
    margin-top: 200px;
  }

  .fap-vv-callout {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
    .video-visit-icon {
      img {
        opacity: 0.6;
      }
    }
  }

  .landing-callout {
    margin: 20px 0 10px;
  
    .icon-outer {
      height: 40px;
      width: 40px;
      background-color: #f8f8f8;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
      margin-top: 10px;
    }
  
    .icon-wrapper {
      color: #666;
      font-size: 18px;
      position: relative;
      top: 12px;
      left: 12px;
    }
  }
}

/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Responsive iFrames
----------------------------------------------------------*/
.fap-search-form-wrapper {
  position: relative;
  display: block; }
  .fap-search-form-wrapper .form-mini-container {
    border-radius: 12px;
    color: #fff;
    border: 10px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 100;
    margin: 10px; }
    @media screen and (min-width: 992px) {
      .fap-search-form-wrapper .form-mini-container {
        width: 960px;
        margin: 140px auto 60px; } }
    @media screen and (min-width: 1200px) {
      .fap-search-form-wrapper .form-mini-container {
        width: 900px;
        margin: 160px auto 80px; } }
    .fap-search-form-wrapper .form-mini-container .fad-wcm-logo {
      text-align: center; }
      .fap-search-form-wrapper .form-mini-container .fad-wcm-logo img {
        display: none; }
        @media screen and (min-width: 768px) {
          .fap-search-form-wrapper .form-mini-container .fad-wcm-logo img {
            display: inline-block;
            width: 300px;
            margin: 10px 0 20px;
            margin-left: 45px; } }
    .fap-search-form-wrapper .form-mini-container .fad-marketing-text {
      margin-bottom: 20px;
      font-size: 25px;
      font-family: "1898Sans-Bold", sans-serif;
      color: #555555;
      text-align: center; }
      @media screen and (min-width: 768px) {
        .fap-search-form-wrapper .form-mini-container .fad-marketing-text {
          font-size: 30px;
          margin-left: 45px; } }
    .fap-search-form-wrapper .form-mini-container .fad-marketing-text-bottom {
      padding: 15px 0 10px;
      text-align: center; }
      .fap-search-form-wrapper .form-mini-container .fad-marketing-text-bottom span {
        font-size: 0.9rem;
        color: #666666;
        text-transform: uppercase;
        margin-top: 0.5rem;
        font-family: "1898Sans-Bold", sans-serif;
        margin-right: 7px;
        letter-spacing: 0.05rem; }
        @media screen and (min-width: 992px) {
          .fap-search-form-wrapper .form-mini-container .fad-marketing-text-bottom span {
            font-size: 1.2rem; } }
      .fap-search-form-wrapper .form-mini-container .fad-marketing-text-bottom img {
        width: 140px;
        position: relative;
        top: -3px; }
        @media screen and (min-width: 992px) {
          .fap-search-form-wrapper .form-mini-container .fad-marketing-text-bottom img {
            width: 215px;
            top: 1px; } }
  .fap-search-form-wrapper .bg-image-wrapper img {
    width: 100%; }
  @media screen and (min-width: 768px) {
    .fap-search-form-wrapper .fap-search-form {
      background: url(../../../../themes/weillcornell/images/find-a-doctor-3.jpg) 50% -10px;
      background-size: cover;
      background-repeat: no-repeat; } }
  @media screen and (min-width: 1450px) {
    .fap-search-form-wrapper .fap-search-form {
      background-position: 50% -50px; } }
  @media screen and (min-width: 2000px) {
    .fap-search-form-wrapper .fap-search-form {
      background-position: 50% -80px; } }
  .fap-search-form-wrapper .fap-search-form h1 {
    font-size: 40px;
    color: #fff; }
  .fap-search-form-wrapper .fap-search-form h3, .fap-search-form-wrapper .fap-search-form legend {
    text-align: center;
    color: #fff;
    font-size: 28px;
    margin-bottom: 15px;
    text-shadow: 0px 3px 9px #444;
    padding-top: 40px; }
    @media screen and (min-width: 768px) {
      .fap-search-form-wrapper .fap-search-form h3, .fap-search-form-wrapper .fap-search-form legend {
        font-size: 36px;
        margin-bottom: 30px;
        margin: 60px;
        padding-top: 50px; } }
  .fap-search-form-wrapper .fap-search-form legend {
    display: none;
    float: none;
    font-family: "1898Sans-Regular", sans-serif;
    margin: 25px 0 10px;
    line-height: 1.3;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 20px;
    border-bottom: 0; }
    @media screen and (min-width: 768px) {
      .fap-search-form-wrapper .fap-search-form legend {
        display: block;
        padding-bottom: 10px; } }
  .fap-search-form-wrapper .fap-search-form .cta-link {
    text-align: center;
    margin-top: 5px; }
    .fap-search-form-wrapper .fap-search-form .cta-link a {
      font-size: 16px;
      color: #FFF;
      text-shadow: 0px 3px 9px #222; }
  .fap-search-form-wrapper .fap-search-form .fap-search-description {
    line-height: 2;
    font-size: 14px;
    padding-bottom: 10px; }
    .fap-search-form-wrapper .fap-search-form .fap-search-description span {
      font-weight: bold; }
  .fap-search-form-wrapper .fap-search-form fieldset {
    width: 100% !important;
    border: 0px !important;
    padding: 0px !important;
    margin-top: 0;
    margin-left: 0; }
    @media screen and (min-width: 992px) {
      .fap-search-form-wrapper .fap-search-form fieldset {
        margin-top: -5px; } }
  .fap-search-form-wrapper .fap-search-form .form-group {
    margin-bottom: 0; }
  .fap-search-form-wrapper .fap-search-form .react-select-prefix__control {
    border: 1px solid #e6e6e6 !important;
    border-radius: 0; }
    @media screen and (min-width: 768px) {
      .fap-search-form-wrapper .fap-search-form .react-select-prefix__control {
        border-right: none !important; } }
  .fap-search-form-wrapper .fap-search-form .accepting-new-patients {
    margin-top: 15px;
    height: 20px;
    padding-left: 0px; }
    @media screen and (min-width: 768px) {
      .fap-search-form-wrapper .fap-search-form .accepting-new-patients {
        margin-top: 0; } }
    .fap-search-form-wrapper .fap-search-form .accepting-new-patients input {
      margin-right: 8px; }
    .fap-search-form-wrapper .fap-search-form .accepting-new-patients label {
      display: inline-block;
      font-size: 15px;
      font-weight: normal; }
  .fap-search-form-wrapper.new-search .fap-search-form legend {
    display: none; }
  .fap-search-form-wrapper.current-search .fap-search-form {
    background: none; }
    .fap-search-form-wrapper.current-search .fap-search-form legend {
      display: none; }
    .fap-search-form-wrapper.current-search .fap-search-form .form-mini-container {
      margin-bottom: 0;
      margin-top: 0;
      box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.3); }
  @supports (-ms-ime-align: auto) {
    .fap-search-form-wrapper .fap-search-form {
      background: #cf4520; } }
  .fap-search-form-wrapper .fap-form {
    background-color: rgba(255, 255, 255, 0.9);
    color: #333;
    padding: 20px 30px 15px; }
    @media screen and (min-width: 768px) {
      .fap-search-form-wrapper .fap-form {
        padding: 15px 30px 10px 50px; } }
    .fap-search-form-wrapper .fap-form .input-wrapper, .fap-search-form-wrapper .fap-form .fap-search-description {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .fap-search-form-wrapper .fap-form .input-wrapper, .fap-search-form-wrapper .fap-form .fap-search-description {
          width: 90%;
          float: left; } }
    .fap-search-form-wrapper .fap-form label {
      font-size: 13px;
      font-weight: normal; }
    .fap-search-form-wrapper .fap-form .button-wrapper {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .fap-search-form-wrapper .fap-form .button-wrapper {
          width: 10%;
          float: left; } }
    .fap-search-form-wrapper .fap-form .search-button button {
      margin-top: 10px;
      padding: 9px 10px;
      height: 48px;
      border-radius: 4px;
      background: #cf4520;
      border: none;
      width: 100%; }
      @media screen and (min-width: 768px) {
        .fap-search-form-wrapper .fap-form .search-button button {
          margin-top: 99px;
          margin-left: -2px;
          border-radius: 0 4px 4px 0;
          width: initial; } }
      .fap-search-form-wrapper .fap-form .search-button button:hover {
        border: none;
        background: #b31b1b; }
    .fap-search-form-wrapper .fap-form .search-button img {
      width: 22px;
      margin: 0 4px; }
    .fap-search-form-wrapper .fap-form .form-control {
      max-width: initial; }
    .fap-search-form-wrapper .fap-form .form-control {
      border: 1px solid #a29f9f;
      border-radius: 5px 0 0 5px;
      box-shadow: none;
      padding: 9px 12px;
      height: 48px;
      font-size: 13px; }
      @media screen and (min-width: 768px) {
        .fap-search-form-wrapper .fap-form .form-control {
          border-right: none;
          font-size: 15px; } }
      .fap-search-form-wrapper .fap-form .form-control:focus {
        outline: 5px auto -webkit-focus-ring-color; }

.popular-searches-outer-wrapper {
  background: #f7f7f7; }
  .popular-searches-outer-wrapper .container {
    padding-top: 20px;
    padding-bottom: 30px; }
    @media screen and (min-width: 768px) {
      .popular-searches-outer-wrapper .container {
        padding-top: 30px;
        padding-bottom: 40px; } }
  .popular-searches-outer-wrapper h2 {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase; }
    @media screen and (min-width: 768px) {
      .popular-searches-outer-wrapper h2 {
        text-align: left;
        font-size: 18px; } }
  .popular-searches-outer-wrapper .popular-searches-inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem; }
    .popular-searches-outer-wrapper .popular-searches-inner-wrapper a.item {
      background: #fff;
      color: #000;
      font-size: 16px;
      font-weight: bold;
      padding: 22px 28px 22px 35px;
      border-bottom: none;
      width: 100%;
      position: relative;
      display: flex;
      align-items: center; }
      @media screen and (min-width: 768px) {
        .popular-searches-outer-wrapper .popular-searches-inner-wrapper a.item {
          flex-basis: 32%; } }
      .popular-searches-outer-wrapper .popular-searches-inner-wrapper a.item:hover {
        color: #cf4520; }
      .popular-searches-outer-wrapper .popular-searches-inner-wrapper a.item span {
        flex-grow: 1; }
      .popular-searches-outer-wrapper .popular-searches-inner-wrapper a.item .chevron:after {
        content: " \e802";
        text-decoration: none;
        font-family: fontello;
        color: #cf4520;
        margin-left: 10px; }

.pane-wcmc-react-fap-results .loading {
  margin: 30px auto 20px; }
  .pane-wcmc-react-fap-results .loading .loading-spinner {
    text-align: center; }

.pane-wcmc-react-fap-results .results-outer-wrapper {
  margin-top: 40px; }

.pane-wcmc-react-fap-results .fap-search-results {
  clear: both;
  font-size: 14px; }
  .pane-wcmc-react-fap-results .fap-search-results .search-result-item {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ddd;
    box-shadow: 0px 3px 3px 0px #dddddd; }
    .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details {
      margin-bottom: 1rem; }
      @media screen and (min-width: 768px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details {
          display: flex;
          flex-direction: row;
          justify-content: space-between; } }
      @media screen and (min-width: 768px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details {
          margin-left: 25px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details hr {
        margin: 10px 0 10px; }
      @media screen and (max-width: 767px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details {
          text-align: center; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .provider-title {
        margin-top: 7px;
        margin-bottom: 12px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .provider-title a {
          font-family: "1898Sans-Bold", sans-serif;
          font-size: 18px;
          line-height: 24px;
          color: #cf4520; }
          @media screen and (min-width: 768px) {
            .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .provider-title a {
              font-size: 22px;
              line-height: 28px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .primary-specialty {
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: #555; }
        @media screen and (min-width: 768px) {
          .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .primary-specialty {
            font-size: 18px;
            line-height: 22px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .online-scheduling-tags {
        margin-top: 12px;
        margin-bottom: 15px;
        font-size: 14px;
        color: #555; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .provider-badge {
        display: none; }
      @media screen and (min-width: 768px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details #accepting-patients, .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .simple-tag {
          margin-right: 0px;
          margin-left: 10px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .award-wrapper {
        flex: none; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .award-wrapper .award-image {
          width: 65px; }
          @media screen and (min-width: 768px) {
            .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-main-details .award-wrapper .award-image {
              width: 80px; } }
    .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper {
      background-color: #fff;
      padding: 8px;
      box-shadow: 0.0025rem 0.005rem 0.25rem 0.2rem #ddd;
      margin: 0 80px; }
      @media screen and (max-width: 767px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper {
          text-align: center;
          margin-bottom: 10px; } }
      @media screen and (min-width: 768px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper {
          margin: 0; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper .provider-image-inner-wrapper {
        overflow: hidden;
        padding-bottom: 100%;
        position: relative;
        width: 100%; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper .provider-image-inner-wrapper img {
          position: absolute;
          width: 100%;
          left: 50%;
          margin-left: -50%; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper a {
        border-bottom: none; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper .provider-image-img {
        max-width: 100%; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .provider-image-wrapper .provider-image-img:hover {
          cursor: pointer; }
    @media screen and (min-width: 768px) {
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .accepting-patients-wrapper, .pane-wcmc-react-fap-results .fap-search-results .search-result-item .vv-available-wrapper {
        margin-bottom: 10px;
        float: left; } }
    .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container {
      color: #555;
      font-size: 13px;
      margin: 5px 0 0;
      display: inline-block; }
      @media screen and (min-width: 768px) {
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container {
          margin: 5px 0 20px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag {
        margin-bottom: 6px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag:last-of-type {
          margin-right: 0; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag span:first-of-type {
          margin-right: 5px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .dot {
          height: 14px;
          width: 14px;
          background-color: #66ff33;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 3px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag.not-accepting .dot {
          background-color: #f44242; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .treats-children-icon {
          opacity: 0.6; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .concierge-medicine-icon {
          opacity: 0.6;
          position: relative;
          top: -1px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .icon {
          display: inline-block; }
          .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .icon img {
            width: 17px;
            height: 17px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .online-scheduling-info-container .text-tag .video-visit-icon {
          opacity: 0.6; }
    @media screen and (min-width: 768px) {
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .locations-wrapper {
        margin-top: 30px; } }
    .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container {
      background: #f7f7f7;
      padding: 30px;
      margin-top: 30px; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container:first-of-type {
        margin-top: 0; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-title {
        margin-bottom: 10px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-title a {
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
          color: #b31b1b; }
          @media screen and (min-width: 992px) {
            .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-title a {
              font-size: 18px;
              line-height: 26px; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-address .address-line-1 {
        font-size: 15px;
        color: #555;
        margin-bottom: 10px; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-address .directions-wrapper {
        display: none; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .phone span {
        display: block;
        font-size: 16px;
        color: #000000;
        font-weight: bold;
        margin-bottom: 5px; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .phone .telephone-number {
        font-size: 15px; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .phone .telephone-number a {
          color: #b31b1b; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .phone .telephone-number::before {
          display: inline-block;
          content: '';
          background-image: url("/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/mobile.svg");
          background-size: contain;
          vertical-align: top;
          width: 2rem;
          height: 2rem;
          margin-right: .5rem; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .location-distance {
        font-size: 14px;
        color: #666;
        margin-bottom: 15px; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .cta-button-inner {
        text-align: center; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .cta-container {
        margin-top: 40px; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .cta-button {
        border-radius: 5px;
        padding: 15px 60px;
        font-size: 14px;
        font-weight: 700;
        height: unset; }
        @media screen and (min-width: 768px) and (max-width: 991px) {
          .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .cta-button {
            width: 100%; } }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .direct-scheduling-cta {
        margin-top: 10px;
        text-align: center; }
        .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .direct-scheduling-cta a {
          color: #b31b1b; }
      .pane-wcmc-react-fap-results .fap-search-results .search-result-item .location-listing-container .call-to-action-wrapper .phone-cta .cta-button {
        padding: 15px 30px; }
  .pane-wcmc-react-fap-results .fap-search-results .disable-message p {
    font-size: 1.3rem;
    line-height: 2.0rem; }

.pane-wcmc-react-fap-results .pagination-wrapper {
  text-align: center; }

@media screen and (max-width: 767px) {
  .pane-wcmc-react-fap-results .top-pagination {
    display: none; } }

.pane-wcmc-react-fap-results .top-pagination .pagination {
  margin: 0 0 10px; }

.pane-wcmc-react-fap-results .expander-link {
  border-bottom: none; }
  .pane-wcmc-react-fap-results .expander-link:after {
    content: ' \e80f';
    text-decoration: none;
    font-family: "fontello";
    font-size: 70%;
    padding-left: 4px;
    line-height: 18px; }
    .pane-wcmc-react-fap-results .expander-link:after:hover {
      text-decoration: none; }
  .pane-wcmc-react-fap-results .expander-link.open:after {
    content: ' \e80b'; }
  .pane-wcmc-react-fap-results .expander-link:hover {
    cursor: pointer; }
  .pane-wcmc-react-fap-results .expander-link.expanded:after {
    content: ' \e80b'; }

.pane-wcmc-react-fap-results .more-locations-wrapper {
  width: 100%;
  float: left;
  margin-top: 15px; }

.react-select-prefix__control {
  height: 48px !important;
  border: none !important;
  cursor: text !important;
  font-size: 15px !important; }

.react-select-prefix__control--is-focused {
  outline: 5px auto -webkit-focus-ring-color !important; }

.react-select-prefix__single-value {
  font-size: 15px !important;
  color: #555 !important; }

.react-select-prefix__menu {
  margin-top: 0px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  z-index: 10 !important; }

.react-select-prefix__group-heading {
  text-align: left !important;
  font-size: 12px !important;
  padding-left: 20px !important;
  text-transform: capitalize !important;
  color: #000 !important;
  font-weight: bold !important; }

.react-select-prefix__option {
  padding: 11px 30px !important;
  text-align: left !important;
  color: black !important;
  font-size: 14px !important; }

.react-select-prefix__option--is-focused {
  background: #cf4520 !important;
  color: #FFF !important;
  cursor: pointer !important; }
  .react-select-prefix__option--is-focused:active {
    background: #cf4520 !important; }

.google-map-react-wrapper .info-window-details .info-title a {
  font-weight: bold;
  font-size: 14px;
  color: #b31b1b;
  font-family: "Open Sans", sans-serif; }

.google-map-react-wrapper .info-window-details .address {
  margin-top: 10px;
  font-size: 12px;
  font-family: "Open Sans", sans-serif; }

.react-select-prefix__option--is-selected {
  background: #df5833 !important;
  color: #FFF !important; }

.react-select-prefix__dropdown-indicator {
  color: #cf4520 !important; }
  .react-select-prefix__dropdown-indicator:hover {
    cursor: pointer; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input--focused {
  outline: none; }

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none;
  text-align: left;
  color: #333; }

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 47px;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  font-size: 16px;
  line-height: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-height: 400px;
  overflow-y: auto; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 20px; }
  .react-autosuggest__suggestion a, .react-autosuggest__suggestion .see-more {
    color: #333;
    border-bottom: none;
    transition: none; }
    .react-autosuggest__suggestion a:after, .react-autosuggest__suggestion .see-more:after {
      content: ' \e802';
      text-decoration: none;
      font-family: "fontello";
      font-size: 70%;
      padding-left: 5px;
      position: relative;
      top: -1px;
      color: #e7751d; }
  .react-autosuggest__suggestion span {
    display: inline-block;
    padding-left: 15px;
    font-size: 14px;
    line-height: 19px; }

.react-autosuggest__suggestion--highlighted {
  background: #cf4520;
  color: #fff; }
  .react-autosuggest__suggestion--highlighted a, .react-autosuggest__suggestion--highlighted .see-more {
    color: #fff;
    transition: none; }
    .react-autosuggest__suggestion--highlighted a:after, .react-autosuggest__suggestion--highlighted .see-more:after {
      color: #fff; }

.react-autosuggest__section-container {
  border-top: 1px solid #ededed; }

.react-autosuggest__section-container--first {
  border-top: 0; }

.react-autosuggest__section-title {
  padding: 10px 0 0 20px;
  font-size: 12px;
  color: #000; }

.autocomplete-dropdown-container {
  text-align: left;
  color: #333;
  display: block;
  position: absolute;
  width: 100%;
  border: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
  background-color: #fff;
  font-size: 14px;
  line-height: 19px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  border-top: none !important; }
  @media screen and (min-width: 992px) {
    .autocomplete-dropdown-container {
      width: 190px; } }
  @media screen and (min-width: 1200px) {
    .autocomplete-dropdown-container {
      width: 240px; } }
  .autocomplete-dropdown-container .suggestion-item {
    cursor: pointer;
    padding: 8px 0px;
    background: #fff; }
    .autocomplete-dropdown-container .suggestion-item a {
      color: #333;
      border-bottom: none;
      transition: none; }
      .autocomplete-dropdown-container .suggestion-item a:after {
        content: ' \e802';
        text-decoration: none;
        font-family: "fontello";
        font-size: 70%;
        padding-left: 5px;
        position: relative;
        top: -1px;
        color: #e7751d; }
    .autocomplete-dropdown-container .suggestion-item span {
      display: inline-block;
      padding-left: 20px;
      font-size: 14px;
      line-height: 20px; }
  .autocomplete-dropdown-container .suggestion-item--active {
    cursor: pointer;
    background: #cf4520;
    color: #fff;
    padding: 8px 20px; }
    .autocomplete-dropdown-container .suggestion-item--active a {
      color: #fff;
      transition: none;
      font-size: 14px;
      line-height: 19px; }
      .autocomplete-dropdown-container .suggestion-item--active a:after {
        color: #fff; }

.sortby-container .react-select-container .react-select-prefix__control {
  border: 1px solid #ddd !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }
  @media screen and (min-width: 768px) {
    .sortby-container .react-select-container .react-select-prefix__control {
      margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .sortby-container .react-select-container .react-select-prefix__control {
      width: 100%; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .sortby-container .react-select-container .react-select-prefix__control {
      width: 100%; } }

.select-filter .react-select-container .react-select-prefix__control {
  border: 1px solid #ddd !important;
  border-radius: 0; }

@media screen and (max-width: 767px) {
  .fap-search-review {
    margin-bottom: 0; } }

.fap-search-review .result-overview {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 5px; }
  @media screen and (min-width: 768px) {
    .fap-search-review .result-overview {
      margin-top: 30px;
      margin-bottom: 20px; } }
  .fap-search-review .result-overview .searched-term {
    font-weight: bold; }
  .fap-search-review .result-overview .selected-insurance {
    font-size: 14px;
    margin: 10px 0;
    display: block; }
  .fap-search-review .result-overview .print-icon {
    background: url("/sites/all/themes/weillcornell/images/print-icon_awesome.svg?v2");
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    width: 27px;
    height: 27px;
    margin: 0px 40px 0px 30px;
    float: right;
    filter: brightness(1.2); }
    @media screen and (max-width: 767px) {
      .fap-search-review .result-overview .print-icon {
        display: none; } }
    .fap-search-review .result-overview .print-icon:hover {
      cursor: pointer;
      filter: brightness(0.5); }

.fap-search-review .fap-filters-inner {
  margin-bottom: 15px; }

.filter-chip {
  background: #f2f2f2 !important;
  font-family: "Open Sans", sans-serif !important;
  margin: 2px 10px 2px 0;
  font-size: 13px !important; }

.fap-search-filters, .mobile-filter-wrapper {
  padding: 15px;
  background: #f7f7f7; }
  @media screen and (min-width: 768px) {
    .fap-search-filters, .mobile-filter-wrapper {
      padding: 20px 20px 10px 20px;
      margin-bottom: 30px; } }
  .fap-search-filters .filter-search-title, .mobile-filter-wrapper .filter-search-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0; }
    @media screen and (min-width: 768px) {
      .fap-search-filters .filter-search-title, .mobile-filter-wrapper .filter-search-title {
        margin: 0 0 10px 0; } }
  .fap-search-filters .reset-filters, .mobile-filter-wrapper .reset-filters {
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: bold; }
    .fap-search-filters .reset-filters a, .mobile-filter-wrapper .reset-filters a {
      color: #b31b1b; }
  .fap-search-filters .reset-filters-disabled a, .mobile-filter-wrapper .reset-filters-disabled a {
    color: #666;
    border-bottom: none;
    pointer-events: none;
    font-size: 13px;
    margin-bottom: 10px; }
  .fap-search-filters .form-control, .mobile-filter-wrapper .form-control {
    border-radius: 0; }
  .fap-search-filters .filter-filter-wrapper input, .mobile-filter-wrapper .filter-filter-wrapper input {
    padding: 5px;
    margin-left: 10px;
    width: 85%; }
    @media screen and (max-width: 767px) {
      .fap-search-filters .filter-filter-wrapper input, .mobile-filter-wrapper .filter-filter-wrapper input {
        border: 1px solid #e7e7e7; } }
  .fap-search-filters .gender-filter .filter-filter-wrapper, .fap-search-filters .distance-filter .filter-filter-wrapper, .fap-search-filters .visittype-filter .filter-filter-wrapper, .mobile-filter-wrapper .gender-filter .filter-filter-wrapper, .mobile-filter-wrapper .distance-filter .filter-filter-wrapper, .mobile-filter-wrapper .visittype-filter .filter-filter-wrapper {
    display: none; }
  .fap-search-filters .gender-filter .filter-details, .fap-search-filters .distance-filter .filter-details, .fap-search-filters .visittype-filter .filter-details, .mobile-filter-wrapper .gender-filter .filter-details, .mobile-filter-wrapper .distance-filter .filter-details, .mobile-filter-wrapper .visittype-filter .filter-details {
    padding-top: 0;
    overflow: auto; }
  .fap-search-filters .accepting-new-patients .label-wrapper, .mobile-filter-wrapper .accepting-new-patients .label-wrapper {
    font-weight: normal;
    font-size: 13px; }
  .fap-search-filters .video-visit-filter .filter-item, .fap-search-filters .accepting-new-patients-filter .filter-item, .fap-search-filters .treats-children-filter .filter-item, .fap-search-filters .concierge-medicine-filter .filter-item, .mobile-filter-wrapper .video-visit-filter .filter-item, .mobile-filter-wrapper .accepting-new-patients-filter .filter-item, .mobile-filter-wrapper .treats-children-filter .filter-item, .mobile-filter-wrapper .concierge-medicine-filter .filter-item {
    margin-bottom: 0; }
    .fap-search-filters .video-visit-filter .filter-item .checkbox-wrapper, .fap-search-filters .accepting-new-patients-filter .filter-item .checkbox-wrapper, .fap-search-filters .treats-children-filter .filter-item .checkbox-wrapper, .fap-search-filters .concierge-medicine-filter .filter-item .checkbox-wrapper, .mobile-filter-wrapper .video-visit-filter .filter-item .checkbox-wrapper, .mobile-filter-wrapper .accepting-new-patients-filter .filter-item .checkbox-wrapper, .mobile-filter-wrapper .treats-children-filter .filter-item .checkbox-wrapper, .mobile-filter-wrapper .concierge-medicine-filter .filter-item .checkbox-wrapper {
      padding: 6px 7px 6px 12px; }
  .fap-search-filters .video-visit-filter .filter-item > span, .fap-search-filters .accepting-new-patients-filter .filter-item > span, .fap-search-filters .treats-children-filter .filter-item > span, .fap-search-filters .concierge-medicine-filter .filter-item > span, .mobile-filter-wrapper .video-visit-filter .filter-item > span, .mobile-filter-wrapper .accepting-new-patients-filter .filter-item > span, .mobile-filter-wrapper .treats-children-filter .filter-item > span, .mobile-filter-wrapper .concierge-medicine-filter .filter-item > span {
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    font-family: "Open Sans", sans-serif; }
  .fap-search-filters .concierge-medicine-filter .filter-item, .mobile-filter-wrapper .concierge-medicine-filter .filter-item {
    margin-right: 5px; }
  .fap-search-filters .concierge-medicine-tooltip-icon, .mobile-filter-wrapper .concierge-medicine-tooltip-icon {
    position: relative; }
    .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents {
      visibility: hidden;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      width: 250px;
      padding: 20px;
      border-radius: 5px;
      top: 40px;
      box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.35);
      opacity: 0;
      transition: opacity 0.6s; }
      @media screen and (min-width: 768px) {
        .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents {
          top: -30px;
          left: 45px;
          width: 400px; } }
      .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents .title, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents .title {
        font-weight: bold;
        font-size: 14px;
        color: #666666;
        margin-bottom: 10px; }
      .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents p, .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents a, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents p, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents a {
        font-size: 13px;
        line-height: 19px; }
      .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents::after, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents::after {
        content: " ";
        position: absolute;
        bottom: 100%;
        right: 50px;
        border-width: 13px;
        border-style: solid;
        border-color: transparent transparent white transparent; }
        @media screen and (min-width: 768px) {
          .fap-search-filters .concierge-medicine-tooltip-icon .tooltip-contents::after, .mobile-filter-wrapper .concierge-medicine-tooltip-icon .tooltip-contents::after {
            top: 30px;
            right: 100%;
            margin-top: -4px;
            border-color: transparent white transparent transparent; } }
    .fap-search-filters .concierge-medicine-tooltip-icon:hover, .mobile-filter-wrapper .concierge-medicine-tooltip-icon:hover {
      cursor: pointer; }
      .fap-search-filters .concierge-medicine-tooltip-icon:hover .tooltip-contents, .mobile-filter-wrapper .concierge-medicine-tooltip-icon:hover .tooltip-contents {
        visibility: visible;
        opacity: 1; }
  .fap-search-filters .expansion-panel-wrapper .expansion-panel-summary, .mobile-filter-wrapper .expansion-panel-wrapper .expansion-panel-summary {
    padding: 0;
    margin-right: -10px; }
  .fap-search-filters .expansion-panel-wrapper p, .mobile-filter-wrapper .expansion-panel-wrapper p {
    font-size: 16px !important;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    padding-bottom: 0; }
  .fap-search-filters .gender-filter, .fap-search-filters .language-filter, .fap-search-filters .specialty-filter, .fap-search-filters .expertise-filter, .fap-search-filters .distance-filter, .mobile-filter-wrapper .gender-filter, .mobile-filter-wrapper .language-filter, .mobile-filter-wrapper .specialty-filter, .mobile-filter-wrapper .expertise-filter, .mobile-filter-wrapper .distance-filter {
    margin-bottom: 15px; }
    .fap-search-filters .gender-filter .expansion-panel-wrapper, .fap-search-filters .language-filter .expansion-panel-wrapper, .fap-search-filters .specialty-filter .expansion-panel-wrapper, .fap-search-filters .expertise-filter .expansion-panel-wrapper, .fap-search-filters .distance-filter .expansion-panel-wrapper, .mobile-filter-wrapper .gender-filter .expansion-panel-wrapper, .mobile-filter-wrapper .language-filter .expansion-panel-wrapper, .mobile-filter-wrapper .specialty-filter .expansion-panel-wrapper, .mobile-filter-wrapper .expertise-filter .expansion-panel-wrapper, .mobile-filter-wrapper .distance-filter .expansion-panel-wrapper {
      padding: 0 15px; }
    .fap-search-filters .gender-filter .filter-item span:last-of-type, .fap-search-filters .language-filter .filter-item span:last-of-type, .fap-search-filters .specialty-filter .filter-item span:last-of-type, .fap-search-filters .expertise-filter .filter-item span:last-of-type, .fap-search-filters .distance-filter .filter-item span:last-of-type, .mobile-filter-wrapper .gender-filter .filter-item span:last-of-type, .mobile-filter-wrapper .language-filter .filter-item span:last-of-type, .mobile-filter-wrapper .specialty-filter .filter-item span:last-of-type, .mobile-filter-wrapper .expertise-filter .filter-item span:last-of-type, .mobile-filter-wrapper .distance-filter .filter-item span:last-of-type {
      margin-bottom: 0 !important; }
  .fap-search-filters .filter-details, .mobile-filter-wrapper .filter-details {
    padding: 7px 0 7px 10px;
    max-height: 400px;
    overflow-y: auto; }
    .fap-search-filters .filter-details fieldset, .mobile-filter-wrapper .filter-details fieldset {
      width: 100%; }
    .fap-search-filters .filter-details legend, .mobile-filter-wrapper .filter-details legend {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      line-height: 17px;
      padding-bottom: 3px; }
    .fap-search-filters .filter-details .filter-item, .mobile-filter-wrapper .filter-details .filter-item {
      margin: 0 10px 0 0; }
      .fap-search-filters .filter-details .filter-item span, .mobile-filter-wrapper .filter-details .filter-item span {
        font-family: "Open Sans", sans-serif;
        font-size: 13px;
        line-height: 17px; }
      .fap-search-filters .filter-details .filter-item > span:last-of-type, .mobile-filter-wrapper .filter-details .filter-item > span:last-of-type {
        margin-bottom: 5px; }
    .fap-search-filters .filter-details .checkbox-wrapper, .fap-search-filters .filter-details .radiobutton-wrapper, .mobile-filter-wrapper .filter-details .checkbox-wrapper, .mobile-filter-wrapper .filter-details .radiobutton-wrapper {
      padding: 7px 7px 7px 0; }

.fap-filters .select-filter {
  margin-top: 5px; }
  @media screen and (min-width: 1200px) {
    .fap-filters .select-filter .react-select-container .react-select-prefix__menu {
      width: 241px; } }

.fap-filters .location-filter {
  margin-top: 5px; }
  .fap-filters .location-filter input {
    height: 46px; }

.fap-filters .insurance-filter {
  padding-bottom: 15px; }

.fap-filters .location-filter label, .fap-filters .insurance-filter label {
  font-size: 16px;
  margin-bottom: 8px; }

.sorting-options .filter-search-title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0; }

@media screen and (max-width: 767px) {
  .sorting-options {
    padding: 10px 15px 20px;
    min-height: 350px;
    min-width: 270px; }
    .sorting-options .col-xs-12 {
      padding: 0; }
    .sorting-options .sortby-container {
      margin-top: 10px; } }

@media screen and (min-width: 768px) {
  .sorting-options .filter-search-title {
    display: none; } }

.show-modal {
  margin: 7px auto; }
  .show-modal a {
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 15px;
    font-weight: 500;
    padding: 8px;
    width: 70%;
    display: block;
    text-align: center;
    margin: 0 auto; }

.done-button-wrapper {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px; }

.panel-flexi-a-fullwidth #main-content {
  padding: 0; }

.date-scrubber-and-sort {
  padding: 0px !important; }
  @media screen and (min-width: 768px) {
    .date-scrubber-and-sort .col-md-2 {
      padding: 0px !important; } }

.view-number-results .react-select-container .react-select-prefix__control {
  border: 1px solid #ddd !important;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px; }
  @media screen and (min-width: 768px) {
    .view-number-results .react-select-container .react-select-prefix__control {
      margin-bottom: 20px; } }
  @media screen and (max-width: 767px) {
    .view-number-results .react-select-container .react-select-prefix__control {
      width: 100%; } }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .view-number-results .react-select-container .react-select-prefix__control {
      width: 100%; } }

.filter-and-results-wrapper {
  border-top: 1px solid #e7e7e7; }
  @media screen and (min-width: 768px) {
    .filter-and-results-wrapper {
      padding-top: 20px; } }
  @media screen and (max-width: 767px) {
    .filter-and-results-wrapper {
      border-top: none;
      padding-top: none; }
      .filter-and-results-wrapper .loading {
        margin-top: 20px;
        margin-bottom: 30px; }
      .filter-and-results-wrapper .row {
        display: flex;
        flex-direction: column; }
        .filter-and-results-wrapper .row .col-xs-12:first-of-type {
          order: 2; }
        .filter-and-results-wrapper .row .standard-widget {
          order: 1; }
        .filter-and-results-wrapper .row .col-xs-12:last-of-type {
          order: 3; } }
  .filter-and-results-wrapper .loading {
    margin-top: 200px; }
  .filter-and-results-wrapper .fap-vv-callout {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center; }
    .filter-and-results-wrapper .fap-vv-callout .video-visit-icon img {
      opacity: 0.6; }
  .filter-and-results-wrapper .landing-callout {
    margin: 20px 0 10px; }
    .filter-and-results-wrapper .landing-callout .icon-outer {
      height: 40px;
      width: 40px;
      background-color: #f8f8f8;
      border-radius: 50%;
      margin-right: 10px;
      float: left;
      margin-top: 10px; }
    .filter-and-results-wrapper .landing-callout .icon-wrapper {
      color: #666;
      font-size: 18px;
      position: relative;
      top: 12px;
      left: 12px; }

.pane-wcmc-in-your-neighborhood-events {
  margin-top: 20px; }
  .pane-wcmc-in-your-neighborhood-events h3 {
    font-size: 30px; }
  .pane-wcmc-in-your-neighborhood-events .view-header {
    margin-bottom: 10px; }
